import React, { useState, useEffect } from 'react';

import axios from 'axios';

// import { OrderList } from 'primereact/orderlist';
import { DataTable } from 'primereact/datatable';
import { Galleria } from 'primereact/galleria'; 
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

import { NEWS, STATE_LIST, DISTRICT_LIST, IMG_PATH, NEWS_UPLOAD } from '../apiurls';
import { UserState } from './loginContext';

import Close from './close-circle-fill.png';

const Home = () => {
    const [sId, setSId] = useState('');

    const [date, setDate] = useState(null);
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [stId, setStId] = useState(0);
    const [dId, setDId] = useState(0);
    const [imgFile, setIFile] = useState(null);
    const [imgs, setImgs] = useState(null);
    const [selImgs, setSImgs] = useState([]);
    const [selObj, setSObj] = useState('');
    const [seq, setSeq] = useState(2);

    const [newsList, setNList] = useState([]);
    const [stateList, setSList] = useState([]);
    const [districtList, setDList] = useState([]);

    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [err, setErr] = useState(false);
    const [opnDel, setODel] = useState(false);
    const [opnView, setOView] = useState(false);
    const [opnImg, setOImg] = useState(false);

    const { user } = UserState();

    const onUpload = (e) => {
        // if(imgFile){
        //     let arr = [...imgFile];

        //     e.target.files[0].id= arr.length;
    
        //     arr.push(e.target.files[0]);
    
        //     setIFile(arr);
        // }else{
            // let arr = [];

            // e.target.files[0].id=arr.length;

            // arr.push(e.target.files[0]);

            // setIFile(arr);
            
            setIFile(e.target.files[0]);
        // };
    };

    let fileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    const header = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="text-xl text-900 font-bold clr-gold">NewsPaper</div>
            
            <Button 
                style={{ width: '100px' }} icon={<i className='pi pi-plus' style={{fontSize: '18px', fontWeight: '600'}}></i>}
                raised severity='warning' onClick={() => setOpen(true)}
                label={<div style={{ fontSize: '18px', fontWeight: '600' }}>Add</div>}
            />
        </div>
    );

    // const itemTemplate = (item) => {
    //     return (
    //         <div className="product-item" style={{display: 'flex', justifyContent: 'space-between'}}>
    //             <div className="image-container">
    //                 <img src={URL.createObjectURL(item)} alt={item.name} style={{width: '80px', height: '45px'}} />

    //                 <div style={{fontSize: '14px'}}>{item.name}</div>
    //             </div>

    //             <div className="product-list-detail" style={{justifyContent: 'center', alignItems: 'center'}}>
    //                 <img src={Close} style={{width: '45px', height: 'auto'}} className="product-category-icon" onClick={() => handleDelete(item)} />
    //             </div>
    //         </div>
    //     );
    // };

    // const handleDelete = (itm) => {
    //     let arr = imgs.filter((it) => it.name !== itm.name);

    //     setImgs(arr);
    // };

    const handleDate = (val) => {
        let date = new Date(val);

        return `${date.getFullYear()}-${((date.getMonth()+1)<10) ? '0'+(date.getMonth()+1) : date.getMonth()+1}-${(date.getDate()<10) ? '0'+date.getDate() : date.getDate()}`
    };

    const handleClose = () => {
        setDate(null); setState(''); setDistrict(''); setSId('');
        setStId(0); setDId(0); setIFile(null); setOpen(false);

        getNews();
    };

    const handleSubmit = () => {
        if(date && state && district && imgFile!==null){
            setErr(false); setLoad(true);

            let imgs = [];
            const forms = new FormData();
            
            // if(imgFile.length){
            //     for(let i=0; i<imgFile.length; i++){
                imgs.push({ 'image_name': imgFile.name, 'sequence': 1 });
                forms.append('newsImages', imgFile, imgFile.name);
            //     };
            // };

            if(sId){
                axios({
                    method: 'put',
                    url: NEWS+'/'+sId, 
                    data: {
                        'state_id': stId,
                        'state_name': state,
                        'district_id': dId,
                        'district_name': district,
                        'date': handleDate(date),
                        'updated_by': user.data.id,
                        'imagesData': imgs
                    }
                }).then((res) => {      
                    console.log(res.data);

                    axios({
                        method: 'put',
                        url: NEWS+'/'+sId,
                        data: forms,
                        headers: { "content-type": "multipart/form-data" }
                    }).then((res) => {
                        console.log(res.data); handleClose();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            }else{
                axios({
                    method: 'post',
                    url: NEWS, 
                    data: {
                        'state_id': stId,
                        'state_name': state,
                        'district_id': dId,
                        'district_name': district,
                        'date': handleDate(date),
                        'created_by': user.data.id,
                        'updated_by': user.data.id,
                        'imagesData': imgs
                    }
                }).then((res) => { 
                    axios({
                        method: 'put',
                        url: NEWS+'/'+res.data.data.id,
                        data: forms,
                        headers: { "content-type": "multipart/form-data" }
                    }).then((res) => {
                        console.log(res.data); handleClose();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            };
        }else{
            setErr(true); setLoad(false);
        };
    };

    const handleState = (val) => {
        setStId(val); 

        for(let i=0; i<stateList.length; i++){
            if(stateList[i].id===val){
                setState(stateList[i].lookup_name);
            };
        };
    };

    const handleDistrict = (val) => {
        setDId(val);

        for(let i=0; i<districtList.length; i++){
            if(districtList[i].id === val){
                setDistrict(districtList[i].lookup_name);
            };
        };
    };

    const getOptions = () => {
        axios({
            method: 'get',
            url: STATE_LIST,
        }).then((res) => {
            setSList(res.data);
        }).catch((err) => {
            console.log(err);
        });

        axios({
            method: 'get',
            url: DISTRICT_LIST,
        }).then((res) => {
            setDList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getNews = () => {
        setLoad(true);

        axios({
            method: 'get',
            url: NEWS,
        }).then((res) => {
            setNList(res.data.rows); setLoad(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleEdit = (row) => {
        setSId(row.id); setDId(row.district_id); setIFile(row.images);
        setStId(row.state_id); setState(row.state_name);
        setDistrict(row.district_name); setOpen(true); setDate(new Date(row.date));
    };

    const handleDel = (row) => {
        setSId(row.id); setODel(true);
    };

    const handleView = (row) => {
        axios({
            method: 'get',
            url: NEWS+'/'+row.id,
        }).then((res) => {
            setSObj(res.data); setOView(true); 
        }).catch((err) => {
            console.log(err);
        });
    };

    const submitDel = () => {
        axios({
            method: 'delete',
            url: NEWS+'/'+sId,
        }).then((res) => {
            console.log(res); 
            setSId(''); setODel(false); getNews();
        }).catch((err) => {
            console.log(err);
        });
    };

    const actionBody = (row) => {
        return <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div style={{color: 'grey'}} className='pi pi-file' onClick={() => handleView(row)}></div>

            <div style={{color: 'yellow'}} className='pi pi-pencil' onClick={() => handleEdit(row)}></div>

            <div style={{color: 'red'}} className='pi pi-trash' onClick={() => handleDel(row)}></div>
        </div>
    };

    const handleUploadImgs = () => {
        // setOImg(false);
        
        const forms = new FormData();
        forms.append('newsImages', imgs, imgs.name);
        
        axios({
            method: 'post',
            url: NEWS_UPLOAD,
            data: {
                news_id: sId,
                image_path: '',
                sequence: seq,
                created_by: user.data.id,
                updated_by: user.data.id,
            },
        }).then((res) => {
            console.log(res);

            axios({
                method: 'put',
                url: NEWS_UPLOAD+'/'+res.data.data.id,
                data: forms,
                headers: { "content-type": "multipart/form-data" }
            }).then((res) => {
                console.log(res.data); 
                setOImg(false); setImgs(null);
            }).catch((err) => {
                console.log(err);
            });
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleUploads = (val) => {
        let sequence = seq===0 ? imgFile[imgFile.length-1].sequence+1 : seq+1;

        val.id = 2; val.sequence = sequence;

        setImgs(val); setSeq(sequence);
    };

    useEffect(() => {
        getNews(); getOptions();
    }, []);

    const itmTemplate = (item) => {
        return <img src={IMG_PATH+item.image_path} alt={item.sequence} style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item) => {
        return <img src={IMG_PATH+item.image_path} alt={item.sequence} style={{ display: 'block' }} />;
    };

    const imgBody = (row) => {
        return <img src={IMG_PATH+row.image_path} style={{ width: '75px', height: 'auto' }} />
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2vh'}}>
            <Card style={{width: '99%', margin: '5px'}}>
                <DataTable 
                    header={header} removableSort value={newsList} scrollable scrollHeight="50vh" paginator loading={load}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
                >
                    <Column field="date" header="Date" sortable />
                    <Column field="state_name" header="State" sortable />
                    <Column field="district_name" header="District" sortable />
                    <Column header='Actions' body={actionBody} />
                </DataTable>
            </Card>

            {/* Add / Edit Pop-up */}
            <Dialog 
                header={<div>{sId ? 'Edit Newspaper' : 'Add Newspaper'}</div>}
                headerStyle={{backgroundColor: '#fff'}}
                style={{width: '85vw', height: 'auto'}} 
                onHide={() => handleClose()} visible={open} 
            >
                <div className='grid nested-grid'>
                    <div className='col-5'>
                        <div style={{width: '99%'}}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <label htmlFor='upload'>
                                    {imgFile===null ? <div style={(err===false && imgFile===null) ?{
                                        fontSize: '18px', display: 'flex', alignItems: 'center',
                                        border: '2px dashed #ccc', borderRadius: '5px', fontWeight: '600',
                                        height: '150px', width: 'auto', justifyContent: 'center',
                                    } : {
                                        fontSize: '18px', display: 'flex', alignItems: 'center',
                                        border: '2px dashed #ccc', borderRadius: '5px', fontWeight: '600',
                                        height: '150px', width: 'auto', justifyContent: 'center', color: 'red'
                                    }}>
                                        Upload Image <span style={{color: 'red'}}>*</span>
                                    </div> : <div>
                                        <div className='pi pi-times-circle' style={{
                                            position: 'absolute', color: 'red', 
                                            fontSize: '19px', fontWeight: '700', 
                                            marginLeft: '162px', marginTop: '5px'
                                        }} onClick={() => setIFile(null)}>&nbsp;</div>

                                        {sId ? imgFile.name ? <img src={URL.createObjectURL(imgFile)} style={{width: '100px', height: '150px'}} /> : <img src={IMG_PATH+imgFile[0].image_path} style={{width: '100px', height: '150px'}} /> : <img src={URL.createObjectURL(imgFile)} style={{width: '100px', height: '150px'}} />}
                                    </div>}
                                </label>

                                <input 
                                    type="file" id='upload' 
                                    accept={fileTypes} 
                                    onChange={(e) => onUpload(e)} 
                                    style={{display: 'none'}}
                                />

                                <small style={{textAlign: 'center'}}>only (png, jpg, jpeg)<span style={{color: 'red'}}>*</span></small>
                            </div>
                        </div>
                    </div>

                    <div className='col-7'>
                        <div className='grid'>
                            <div className='col-6'>
                                <label style={(err===true&&date===null) ? { color: 'red' } : {}}>Enter Date</label>

                                <Calendar 
                                    value={date} style={{width: '100%'}}
                                    onChange={(e) => setDate(e.value)} 
                                    dateFormat='dd/mm/yy' 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&stId==='') ? { color: 'red' } : {}}>Enter State</label>

                                <Dropdown 
                                    options={stateList} optionLabel='lookup_display_name' 
                                    optionValue='id' value={stId} style={{width: '100%'}} 
                                    onChange={(e) => handleState(e.value)} 
                                />
                            </div>

                            <div className='col-6'>
                                <label style={(err===true&&dId==='') ? { color: 'red' } : {}}>Enter District</label>

                                <Dropdown 
                                    options={districtList} optionLabel='lookup_display_name' 
                                    optionValue='id' value={dId} style={{width: '100%'}} 
                                    onChange={(e) => handleDistrict(e.value)} 
                                />
                            </div>
                        </div>
                    </div>
                    
                    {sId!=='' ?
                        <DataTable 
                            value={imgFile} removableSort style={{width: '100%'}}
                            header={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{color: 'gold', fontSize: '20px'}}>Newspaper Images</div>
                                <div><Button label='Add Images' onClick={() => setOImg(true)} /></div>
                            </div>}
                        >
                            <Column field='sequence' header='Serial No.' sortable />
                            <Column field='image_path' header='Image' body={imgBody} />
                        </DataTable>
                    : '' }

                    <div className='col-12' style={{textAlign: 'center'}}>
                        <Button 
                            label='Submit' style={{width: '200px'}} 
                            onClick={() => handleSubmit()} disabled={load}
                        />
                    </div>
                </div>
            </Dialog>

            {/* Delete Pop-up */}
            <Dialog 
                visible={opnDel} onHide={() => {setSId(''); setODel(false)}} 
                header="Do You Want to Delete ?" style={{width: '35vw', height: 'auto'}}
            >
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{border: '2px solid', padding: '5px'}} onClick={() => {setODel(false); setSId('')}}>No</div>

                    <div style={{border: '2px solid', padding: '5px'}} onClick={submitDel}>Yes</div>
                </div>
            </Dialog>

            {/* View Pop-up */}
            <Dialog 
                visible={opnView} onHide={() => {setOView(false); setSObj('')}} 
                header={<div>View Newspaper - {selObj.date}</div>} style={{width: '55vw', height: '75vh'}}
            >
                {selObj && <div>
                    <div className='grid'>
                        <div className='Col-4'>&nbsp;&nbsp;Date: &nbsp;&nbsp;&nbsp;&nbsp;{selObj.date}</div>

                        <div className='Col-4'>&nbsp;,&nbsp;State: &nbsp;&nbsp;{selObj.state_name}</div>

                        <div className='Col-4'>&nbsp;,&nbsp;District: &nbsp; {selObj.district_name}</div>
                    </div>

                    <div style={{marginTop: '15px'}}>
                        <Galleria 
                            value={selObj.images} 
                            numVisible={5} circular style={{ maxWidth: '540px' }}
                            showItemNavigators showThumbnails={false} 
                            showItemNavigatorsOnHover showIndicators 
                            item={itmTemplate} thumbnail={thumbnailTemplate} 
                        />
                    </div>
                </div>}
            </Dialog>

            {/* Images Pop-up */}
            <Dialog 
                header='Upload Images' visible={opnImg} 
                onHide={() => {setOImg(false)}}
                style={{width: '45vw', height: 'auto'}}
            >
                <div>
                    <div>
                        {imgs===null ? <label htmlFor='uplod' style={{textAlign: 'center', width: '50%'}}>
                            <div style={{fontSize: '18px', fontWeight: 600, color: 'white', backgroundColor: 'dodgerblue', padding: '5px'}}>Upload Image</div>
                        </label> : <div style={{textAlign: 'center'}}>
                            <div style={{position: 'absolute', right: '5%'}}>
                                <img src={Close} style={{width: '35px', height: '35px'}} />
                            </div>

                            <img src={URL.createObjectURL(imgs)} style={{width: '300px', height: '270px'}} />
                        </div>}

                        {imgs===null && <small style={{display: 'flex', justifyContent: 'center', marginTop: '5px', fontSize: '12px'}}>only (png, jpg, jpeg)<span style={{color: 'red'}}>*</span></small>}

                        <input type='file' id='uplod' onChange={(e) => handleUploads(e.target.files[0])} style={{display: 'none'}} />
                    </div>
                </div>

                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    <Button label='Submit Images' onClick={handleUploadImgs} />
                </div>
            </Dialog>
        </div>
    );
};

export default Home;