import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

import { LOOKUPTYPE } from '../apiurls';
import { UserState } from './loginContext';

const LookupType = () => {
    const [open, setOpen] = useState(false);
    const [opnDel, setODel] = useState(false);
    const [load, setLoad] = useState(false);
    const [err, setErr] = useState(false);

    const [sId, setSId] = useState('');

    const [typeList, setTList] = useState([]);

    const [lookupType, setLType] = useState('');
    const [display, setDisplay] = useState('');
    const [desc, setDesc] = useState('');

    const { user } = UserState();
    
    const header = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="text-xl text-900 font-bold clr-gold">LookUp Types</div>
            
            <Button 
                style={{ width: '100px' }} icon={<i className='pi pi-plus' style={{fontSize: '18px', fontWeight: '600'}}></i>}
                raised severity='warning' onClick={() => setOpen(true)}
                label={<div style={{ fontSize: '18px', fontWeight: '600' }}>Add</div>}
            />
        </div>
    );

    const handleSubmit = () => {
        if(desc && lookupType && display){
            setErr(false); setLoad(true);

            let obj = {
                lookup_type: lookupType,
                lookup_type_displayname: display,
                description: desc,
                updated_by: user.id,
            };

            if(sId){
                axios({
                    method: 'put',
                    url: LOOKUPTYPE+'/'+sId,
                    data: obj,
                }).then((res) => {
                    handleClose(); getLookupType();
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                obj.created_by = user.id;

                axios({
                    method: 'post',
                    url: LOOKUPTYPE,
                    data: obj,
                }).then((res) => {
                    handleClose(); getLookupType();
                }).catch((err) => {
                    console.log(err);
                });
            };
        }else{
            setErr(true); setLoad(false);
        };
    };

    const handleEdit = (row) => {
        setOpen(true); setSId(row.id); 
        setLType(row.lookup_type); setDesc(row.description); 
        setDisplay(row.lookup_type_displayname);
    };

    const handleDel = (row) => {
        setSId(row.id); setODel(true);
    };

    const handleClose = () => {
        setOpen(false); setDesc(''); 
        setLType(''); setSId(''); setDisplay(''); 
    };

    const actionBody = (row) => {
        return <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div className='pi pi-pencil' style={{color: 'yellow'}} onClick={() => handleEdit(row)}></div>

            <div className='pi pi-trash' style={{color: 'red'}} onClick={() => handleDel(row)}></div>
        </div>
    };

    const getLookupType = () => {
        setLoad(true);

        axios({
            method: 'get',
            url: LOOKUPTYPE
        }).then((res) => {
            setTList(res.data.rows);
            setLoad(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const submitDel = () => {
        axios({
            method: 'delete',
            url: LOOKUPTYPE+'/'+sId,
        }).then((res) => {
            console.log(res); 
            setSId(''); setODel(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        getLookupType();
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2vh'}}>
            <Card style={{width: '99%', margin: '5px'}}>
                <DataTable header={header} removableSort value={typeList} loading={load}>
                    <Column field="lookup_type" header="Lookup Type" sortable />
                    <Column field="lookup_type_displayname" header="Display Name" sortable />
                    <Column field="description" header="Description" sortable />
                    <Column header="Action" body={actionBody} />
                </DataTable>
            </Card>

            <Dialog 
                header={<div>{sId ? "Edit Lookup Type" : 'Add Lookup Type'}</div>} visible={open} headerStyle={{backgroundColor: '#fff'}}
                style={{ width: '85vw', height: 'auto' }} onHide={() => handleClose()}
            >
                <div className='grid'>
                    <div className='col-6'>
                        <label style={(err===true&&lookupType==='') ? {color: 'red'} : {}}>LookupType Name <sup style={{color: 'red'}}>*</sup></label>
                        <InputText value={lookupType} onChange={(e) => setLType(e.target.value)} style={{width: '100%'}} />
                    </div>

                    <div className='col-6'>
                        <label style={(err===true&&display==='') ? {color: 'red'} : {}}>Display Name <sup style={{color: 'red'}}>*</sup></label>
                        <InputText value={display} onChange={(e) => setDisplay(e.target.value)} style={{width: '100%'}} />
                    </div>

                    <div className='col-6'>
                        <label style={(err===true&&desc==='') ? {color: 'red'} : {}}>Description <sup style={{color: 'red'}}>*</sup></label>
                        <InputText value={desc} onChange={(e) => setDesc(e.target.value)} style={{width: '100%'}} />
                    </div>
                        
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <Button 
                            label='Submit' style={{width: '200px'}} 
                            onClick={handleSubmit} disabled={load}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog header='Do you want to Delete ?' visible={opnDel} onHide={() => { setODel(false); setSId('') }} style={{width: '35vw', height: 'auto'}}>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{border: '2px solid', padding: '5px'}} onClick={() => {setSId(''); setODel(false)}}>No</div>

                    <div style={{border: '2px solid', padding: '5px'}} onClick={() => submitDel()}>Yes</div>
                </div>
            </Dialog>
        </div>
    );
};

export default LookupType;