import { Route, Routes } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

import Home from './component/home';
import Login from './component/login';
import Users from './component/users';
import Header from './component/header';
import SideMenu from './component/sidebar';
import LookupCode from './component/lookupcode';
import LookupType from './component/lookuptype';

import { UserState } from './component/loginContext';

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "/node_modules/primeflex/primeflex.css";
import 'primeicons/primeicons.css';

import './App.css';

function App() {
  const { user, opnSide } = UserState();

  return (
    <PrimeReactProvider>
      <div className='App-header'>
        <div style={user!==null ? {height: '10%'} : { display: 'none'}}>
          <Header />
        </div>
      
        <div style={user!==null ? {height: '90%'} : {height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={opnSide===true ? {width: '20vw', height: '90vh'} : {display: 'none'}}>
              <SideMenu />
            </div>

            <div style={opnSide===true ? {width: '80vw'} : {width: '100vw'}}>
              <Routes>
                <Route index path='/' element={user===null ? <Login /> : <Home />} />
                {user!==null ? <>
                  <Route path='/news' element={<Home />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/lookupcode' element={<LookupCode />} />
                  <Route path='/lookuptype' element={<LookupType />} />
                  <Route path='/login' element={<Login />} />
                </> : <Route path='/login' element={<Login />} />}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </PrimeReactProvider>
  );
};

export default App;