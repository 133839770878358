import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

import { LOOKUPCODE, LOOKUPTYPE } from '../apiurls';
import { UserState } from './loginContext';

const LookupCode = () => {
    const [open, setOpen] = useState(false);
    const [opnDel, setODel] = useState(false);
    const [err, setErr] = useState(false);
    const [load, setLoad] = useState(false);

    const [codeList, setCList] = useState([]);
    const [typeList, setTList] = useState([]);
    const [sId, setSId] = useState('');

    const [lookupCode, setLCode] = useState('');
    const [lookupType, setLType] = useState('');
    const [ltId, setLTId] = useState('');
    const [display, setDisplay] = useState('');
    const [desc, setDesc] = useState('');

    const { user } = UserState();
    
    const header = (
        <div className="flex align-items-center justify-content-between gap-2">
            <div className="text-xl text-900 font-bold clr-gold">LookUp Codes</div>
            
            <Button 
                style={{ width: '100px' }} icon={<i className='pi pi-plus' style={{fontSize: '18px', fontWeight: '600'}}></i>}
                raised severity='warning' onClick={() => setOpen(true)}
                label={<div style={{ fontSize: '18px', fontWeight: '600' }}>Add</div>}
            />
        </div>
    );

    const handleSubmit = () => {
        if(desc!=='' && lookupCode!=='' && display!=='' && lookupType!==''){
            let obj = {
                lookup_type_name: lookupType,
                lookup_type_id: ltId,
                lookup_name: lookupCode,
                lookup_display_name: display,
                description: desc,
                updated_by: user.data.id,
            }; 
            
            setErr(false); setLoad(true);

            if(sId){
                axios({
                    method: 'put',
                    url: LOOKUPCODE,
                    data: obj,
                }).then((res) => {
                    getLookupCode(); handleClose();
                }).catch((err) => {
                    console.log(err);
                });
            }else{
                obj.created_by = user.data.id;

                axios({
                    method: 'post',
                    url: LOOKUPCODE,
                    data: obj,
                }).then((res) => {
                    console.log(res); getLookupCode(); handleClose();
                }).catch((err) => {
                    console.log(err);
                });
            };
        }else{
            setErr(true); setLoad(false);
        };
    };

    const actionBody = (row) => {
        return <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div style={{color: 'yellow'}} className='pi pi-pencil' onClick={() => handleEdit(row)}></div>

            <div style={{color: 'red'}} className='pi pi-trash' onClick={() => handleDel(row)}></div>
        </div>
    };

    const handleEdit = (row) => {
        setOpen(true); setSId(row.id); setLCode(row.lookup_name);
        setDesc(row.description); setDisplay(row.lookup_display_name);
        setLTId(row.lookup_type_id); setLType(row.lookup_type_name);
    };

    const handleDel = (row) => {
        setSId(row.id); setODel(true);
    };

    const handleClose = () => {
        setOpen(false); setDesc(''); 
        setLCode(''); setSId('');
        setDisplay(''); 
    };

    const getLookupCode = () => {
        setLoad(true);

        axios({
            method: 'get',
            url: LOOKUPCODE,
        }).then((res) => {
            setCList(res.data.rows); setLoad(false);
        }).catch((err) => {
            console.log(err);
        });

        axios({
            method: 'get',
            url: LOOKUPTYPE,
        }).then((res) => {
            setTList(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    };

    const submitDel = () => {
        axios({
            method: 'delete',
            url: LOOKUPCODE+'/'+sId,
        }).then((res) => {
            console.log(res); 
            setODel(false); setSId('');
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleType = (val) => {
        setLTId(val.value);

        for(let i=0; i<typeList.length; i++){
            if(typeList[i].id === val.value){
                setLType(typeList[i].lookup_type);
                console.log(typeList[i], typeList[i].lookup_type);
            };
        };
    };

    useEffect(() => {
        getLookupCode();
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2vh'}}>
            <Card style={{width: '99%', margin: '5px'}}>
                <DataTable header={header} removableSort value={codeList} loading={load}>
                    <Column field="lookup_type_name" header="Lookup Type" sortable />
                    <Column field="lookup_name" header="Lookup Code" sortable />
                    <Column field="lookup_display_name" header="Display Name" sortable />
                    <Column field="description" header="Description" sortable />
                    <Column field="" header="Actions" body={actionBody} />
                </DataTable>
            </Card>

            {/* Add / Edit Pop-up */}
            <Dialog 
                header={<div>{sId? 'Edit Lookup Code' : 'Add Lookup Code'}</div>} 
                visible={open} headerStyle={{backgroundColor: '#fff'}}
                style={{ width: '85vw', height: 'auto' }} onHide={() => handleClose()}
            >
                <div className='grid'>
                    <div className='col-6'>
                        <label style={(err===true&&ltId==='') ? {color: 'red'} : {}}>Lookup Type <sup style={{color: 'red'}}>*</sup></label>
                        <Dropdown 
                            value={ltId} onChange={(e) => handleType(e)} 
                            options={typeList} optionLabel='lookup_type_displayname' 
                            optionValue='id' style={{width: '100%'}} 
                        />
                    </div>

                    <div className='col-6'>
                        <label style={(err===true&&lookupCode==='') ? {color: 'red'} : {}}>Lookup Code <sup style={{color: 'red'}}>*</sup></label>
                        <InputText 
                            value={lookupCode} style={{width: '100%'}}
                            onChange={(e) => setLCode(e.target.value)} 
                        />
                    </div>

                    <div className='col-6'>
                        <label style={(err===true&&display==='') ? {color: 'red'} : {}}>Display Name <sup style={{color: 'red'}}>*</sup></label>
                        <InputText 
                            value={display} style={{width: '100%'}}
                            onChange={(e) => setDisplay(e.target.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <label style={(err===true&&desc==='') ? {color: 'red'} : {}}>Description <sup style={{color: 'red'}}>*</sup></label>
                        <InputText 
                            value={desc} style={{width: '100%'}}
                            onChange={(e) => setDesc(e.target.value)} 
                        />
                    </div>
                        
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <Button 
                            label='Submit' style={{width: '200px'}} 
                            onClick={handleSubmit} disabled={load}
                        />
                    </div>
                </div>
            </Dialog>

            {/* Delete Pop-up */}
            <Dialog visible={opnDel} onHide={() => {setODel(false); setSId('')}}>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{border: '2px solid', padding: '5px'}} onClick={() => {setODel(false); setSId('')}}>No</div>

                    <div style={{border: '2px solid', padding: '5px'}} onClick={submitDel}>Yes</div>
                </div>
            </Dialog>
        </div>
    );
};

export default LookupCode;